quill-editor {
    display: block;
    border: 0;
  }
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: 0;
  }
  .container {
    width: 500px;
    max-height: 500px;
    margin: 10px;
    border: 1px solid #fff;
    background-color: #ffffff;
    box-shadow: 0px 2px 7px #292929;
    -moz-box-shadow: 0px 2px 7px #292929;
    -webkit-box-shadow: 0px 2px 7px #292929;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
  }
  .mainbody,
  .header,
  .footer {
    padding: 5px;
  }
  .mainbody {
    margin-top: 0;
    min-height: 150px;
    max-height: 388px;
    overflow: auto;
  }
  .header {
    height: 40px;
    border-bottom: 1px solid #EEE;
    background-color: #ff8080;
    height: 40px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .footer {
    height: 40px;
    background-color: #b3c6ff;
    border-top: 1px solid #DDD;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  section {
    width: 80%;
    height: 200px;   
    margin: auto;
    padding: 10px;
  }
  div#one {
    width: 15%;
    height: 200px;
    background: #99ffbb;
    float: left;
  }
  div#two {
    margin-left: 15%;
    height: 200px;
    background: #ffcccc;
  }

  
  .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
  {
      font-family: "Roboto", cursive;;
      content: "Roboto" !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
  {
      font-family: "Raleway", cursive;;
      content: "Raleway" !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
  {
      font-family: "Montserrat", cursive;;
      content: "Montserrat" !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
  {
      font-family: "Lato", cursive;;
      content: "Lato" !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
  {
      font-family: "Rubik", cursive;;
      content: "Rubik" !important;
  }
  
  /* Set content font-families */
  .ql-font-Roboto {
    font-family: "Roboto";
    src: url(Calibri Regular.ttf);
  }
  .ql-font-Raleway {
    font-family: "Raleway";
  }
  .ql-font-Montserrat {
    font-family: "Montserrat";
  }
  .ql-font-Lato {
    font-family: "Lato";
  }
  .ql-font-Rubik {
    font-family: "Rubik";
  }